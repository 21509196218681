import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Navigation from "sections/Navigation"
import Footer from "sections/Footer-classic"

import Blog from '../../mySections/BlogContent/index'
import PrivacyContent from '../../mySections/TermOfUseData/en/termE_data_basic'

import { GlobalStyle } from "sections/app.style"
import theme from "theme/classic/themeStyles"

const Terms_En = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="Terms of Use" />
      <Navigation pageName="404" />
      <Blog
        title={"Terms of Use"}
        content={PrivacyContent}
      />
      <Footer />
    </Layout>
  </ThemeProvider>
)
export default Terms_En